import {
	IonButton,
	IonCol,
	IonGrid,
	IonItem,
	IonLabel,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonSpinner,
} from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import CardIcon from "assets/images/icon/svg/Card";
import Payment from "assets/images/icon/svg/Payment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	paymentTerm,
	paymentMethod,
	creditFee,
	setPaymentName,
	setPaymentMethodText,
} from "redux/container/paymentMethodSlice";
import { subtotalAmount, totalAmount } from "redux/container/shoppingCartSlice";

import Card from "../Common/Card";
import { cartTotalAmount, shoppingListItem } from "helpers/shoppingCart";
import { digitChanger, numberWithCommas } from "helpers/helper";
import moment from "moment";
import { bankListArray } from "data/bankList";
import { getUser } from "config/user";
import Orders from "services/Orders";
import { last } from "lodash";
import { getLanguage } from "config/language";
import { getProductSeller } from "config/user";
import parse from "html-react-parser";

export default function PaymentCard() {
	let payTerms = useSelector((state) => state.payment.paymentName);
	const [payTrim, setPayTrim] = useState(payTerms);
	// useEffect(() => {
	// 	if (!payTerms) setPayTrim("Select a Payment Terms");
	// }, []);
	const [selectedMethod, setSelectedMethod] = useState({});

	let payMethods = useSelector((state) => state.payment.paymentMethodText);
	let [gateWayList, setGatewayList] = useState([]);
	const [gateWayLoader, setGateWayLoader] = useState(true);

	const cartTotal = useSelector((state) => state.cart.total);
	let [gateTermList, setTermList] = useState([]);
	const [termLoader, setTermLoader] = useState(true);

	const dispatch = useDispatch();

	const [payMethod, setPayMethod] = useState("Select a Payment Method");
	const [paymentTermText, setpaymentTermText] = useState("");
	const [paymentMethodText, setpaymentMethodText] = useState("");
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const selectedDalieveryDate = useSelector(
		(state) => state.payment.deliveryDate
	);
	const [selectedLan, setSelectedLan] = useState(getLanguage());

	const paymentTermsRef = useRef(null);
	const paymentMethodeRef = useRef(null);

	const [isOpenPaymentTerms, setIsOpenPaymentTerms] = useState(false);
	const [isOpenPaymentMethod, setIsOpenPaymentMethod] = useState(false);

	const paymentTermsModalClose = () => {
		// paymentTermsRef.current?.dismiss();
		setIsOpenPaymentTerms(false);
	};
	const paymentMethodModalClose = () => {
		setIsOpenPaymentMethod(false);
		// paymentMethodeRef.current?.dismiss();
	};

	useEffect(() => {
		setPayTrim(payTerms ? payTerms : "Select a Payment Terms");
	}, [payTerms]);
	useEffect(() => {
		setPayMethod(payMethods ? payMethods : "Select a Payment Method");
	}, [payMethods]);

	const delivery = require("assets/images/delivery.png");
	const bank = require("assets/images/bank.png");
	const cash = require("assets/images/Cash.png");
	const bkash = require("assets/images/bkash.png");
	const nogod = require("assets/images/nogod.png");
	const sellerName = () => {
		if (shoppingListItem().length !== 0) {
			return shoppingListItem()[0].seller_business_name;
		} else {
			return "";
		}
	};

	let user = getUser();
	let getTermList = async () => {
		let response = await Orders.paymentTermList(
			getProductSeller()?.seller_business_pg_id
		);

		if (response.status === 200) {
			setTermLoader(false);
			setTermList(response.results);
		} else {
			setTermLoader(false);
		}
	};

	useEffect(() => {
		getTermList();
	}, []);

	let getGaitWayList = async () => {
		let response = await Orders.paymentGaitWayList();
		if (response.status === 200) {
			setGateWayLoader(false);
			setGatewayList(response.results);
		} else {
			setGateWayLoader(false);
		}
	};

	useEffect(() => {
		getGaitWayList();
	}, []);

	const paymentTermHandler = async (e, title) => {
		setPayTrim(title);
		let interVal = setTimeout(() => {
			dispatch(setPaymentName(title));
			clearTimeout(interVal);
		}, 10);
		let selectedDayes = 0;
		let creditfee = e.amount;
		if (title === "At Night of Delivery Day") {
			selectedDayes = 1;
			creditfee = cartTotalAmount() * e.amount;
			setpaymentTermText(
				`${ekkLanguage.createOrder.todayPaymentText1} ${digitChanger(
					numberWithCommas(creditfee)
				)} ${ekkLanguage.createOrder.todayPaymentText2}`
			);
		} else if (title === "Pay in 3 days") {
			selectedDayes = 3;
			creditfee = cartTotalAmount() * e.amount;
			setpaymentTermText(
				`${ekkLanguage.createOrder.threePaymentText1} ${digitChanger(
					numberWithCommas(creditfee)
				)} ${ekkLanguage.createOrder.threePaymentText2}`
			);
		} else if (title === "Pay in 7 days") {
			selectedDayes = 7;
			creditfee = cartTotalAmount() * e.amount;
			setpaymentTermText(
				`${ekkLanguage.createOrder.sevenPaymentText1} ${digitChanger(
					numberWithCommas(creditfee)
				)} ${ekkLanguage.createOrder.sevenPaymentText2}`
			);
		} else {
			setpaymentTermText(ekkLanguage.createOrder.immediatePaymentText);
		}
		dispatch(creditFee(creditfee));
		dispatch(paymentTerm(selectedDayes));
		setTimeout(() => {
			dispatch(totalAmount(cartTotalAmount() + creditfee));
			dispatch(subtotalAmount(cartTotalAmount()));
		}, 10);
	};

	return (
		<div className='px-2 pt-4'>
			<Card title={ekkLanguage.createOrder.paymentDetailsTitle}>
				<IonGrid>
					<IonRow>
						<IonCol size='12'>
							<div className='pt-1'>
								<h4 className='text-success text-14 font-bold pb-3'>
									{ekkLanguage.createOrder.paymentTermsTitle}
								</h4>
								<div
									className={`bg-F3F3F3 p-2 block rounded-[10px] border ${
										payTrim === "Select a Payment Terms"
											? "border-primary"
											: "border-transparent"
									} ${cartTotal > 0 ? "opacity-100" : "opacity-30"}`}
									// id='open-payment-modal'
									onClick={() =>
										setIsOpenPaymentTerms(cartTotal > 0 && true)
									}>
									<div className='flex justify-between items-center'>
										<div className='flex'>
											<span>
												<Payment
													color={
														payTrim === "Select a Payment Terms"
															? "#FF564C"
															: "#222222"
													}
												/>
											</span>
											<p
												className={`text-12 ${
													payTrim === "Select a Payment Terms"
														? "text-primary"
														: "text-black-1000"
												} font-medium ml-2`}>
												{/* Select a Payment Terms */}
												{payTrim}
											</p>
										</div>
										<div>
											<Add
												color={
													payTrim === "Select a Payment Terms"
														? "#FF564C"
														: "#222222"
												}
											/>
										</div>
									</div>
								</div>
							</div>

							<IonModal
								className='paymentTrmsModal'
								// trigger='open-payment-modal'
								isOpen={isOpenPaymentTerms}
								showBackdrop={true}
								ref={paymentTermsRef}>
								<div>
									<Card
										title={ekkLanguage.createOrder.paymentTermsTitle}
										onHide={true}
										closeModal={paymentTermsModalClose}>
										<div>
											<div className='pt-5'>
												<IonRadioGroup value={payTrim}>
													{!termLoader ? (
														gateTermList.length ? (
															gateTermList.map((el, i) => {
																return (
																	<IonItem
																		onClick={() => {
																			paymentTermHandler(
																				el,
																				el.title
																			);
																		}}
																		key={el.id}
																		lines='none'
																		className='relative bg-white border rounded-[10px] mb-5 no-ripple'>
																		<IonLabel className='absolute left-8 w-full border-0 font-sans text-14 text-black-500 font-medium'>
																			<div className='flex'>
																				{selectedLan ===
																				"en" ? (
																					<span
																						span
																						className='ml-2'>
																						{el?.title}
																					</span>
																				) : (
																					<span
																						span
																						className='ml-2'>
																						{
																							el?.own_lg_title
																						}
																					</span>
																				)}
																			</div>
																		</IonLabel>
																		<IonRadio
																			// slot='start'
																			value={el.title}
																			color='success'
																		/>
																	</IonItem>
																);
															})
														) : (
															""
														)
													) : (
														<div className='flex items-center justify-center h-[156px] m-auto'>
															{" "}
															<IonSpinner name='crescent'></IonSpinner>
														</div>
													)}
												</IonRadioGroup>
											</div>
											<p className='font-normal text-12 text-black-500 px-2'>
												{paymentTermText}
											</p>
											<div className='text-center pt-0'>
												<IonButton
													color='primary'
													// onClick={paymentTermsModalClose}
													onClick={() =>
														setIsOpenPaymentTerms(false)
													}>
													{ekkLanguage.createOrder.btnLabel}
												</IonButton>
											</div>
										</div>
									</Card>
								</div>
							</IonModal>
						</IonCol>
						<IonCol size='12'>
							<div className='pt-2'>
								<h4 className='text-success text-14 font-bold pb-3'>
									{ekkLanguage.createOrder.paymentMethodeTitle}
								</h4>
								<div
									className={`bg-F3F3F3 p-2 block rounded-[10px] border ${
										payMethod === "Select a Payment Method"
											? "border-primary"
											: "border-transparent"
									} ${cartTotal > 0 ? "opacity-100" : "opacity-30"}`}
									// id='open-method-modal'
									onClick={() =>
										setIsOpenPaymentMethod(cartTotal > 0 && true)
									}>
									<div className='flex justify-between items-center'>
										<div className='flex'>
											<span>
												<CardIcon
													color={
														payMethod ===
														"Select a Payment Method"
															? "#FF564C"
															: "#222222"
													}
												/>
											</span>
											<p
												className={`text-12 ${
													payMethod === "Select a Payment Method"
														? "text-primary"
														: "text-black-1000"
												} font-medium ml-2 `}>
												{/* Select a Payment Method */}
												{payMethod}
											</p>
										</div>
										<div>
											<Add
												color={
													payMethod === "Select a Payment Method"
														? "#FF564C"
														: "#222222"
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<IonModal
								className='paymentMethodeModal'
								// trigger='open-method-modal'
								isOpen={isOpenPaymentMethod}
								showBackdrop={true}
								ref={paymentMethodeRef}>
								<div>
									<Card
										title={
											ekkLanguage.createOrder.paymentMethodeTitle
										}
										onHide={true}
										closeModal={paymentMethodModalClose}>
										<div>
											<div className='pt-5'>
												<IonRadioGroup
													value={payMethod}
													onIonChange={(e) => {
														if (e.detail.value === "Cash") {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.cashText
																} ${sellerName()}\n`
															);
														} else if (
															e.detail.value === "BKash"
														) {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.bkashText
																} ${sellerName()}\n${
																	ekkLanguage.createOrder
																		.number
																}: 01701898144 \n${
																	ekkLanguage.createOrder
																		.numberWarning
																}`
															);
														} else if (
															e.detail.value === "Nagad"
														) {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.nagadText
																} ${sellerName()}\n${
																	ekkLanguage.createOrder
																		.number
																}: 01701898144\n${
																	ekkLanguage.createOrder
																		.numberWarning
																}`
															);
														} else if (
															e.detail.value === "Islami Bank"
														) {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.bankTransferText1
																} ${sellerName()} ${
																	ekkLanguage.createOrder
																		.bankTransferText2
																}:\n${
																	ekkLanguage.createOrder
																		.bankTransferText3
																}: Islami Bank limited\n${
																	ekkLanguage.createOrder
																		.bankTransferText4
																} EkkBaz Bangladesh Pvt. Ltd.\n${
																	ekkLanguage.createOrder
																		.bankTransferText5
																}: 20503110100185612\n${
																	ekkLanguage.createOrder
																		.bankTransferText6
																}: 125274245\n${
																	ekkLanguage.createOrder
																		.bankTransferText7
																}: Islami Bank Motijheel Branch, Dhaka`
															);
														} else if (
															e.detail.value === "Bank Asia"
														) {
															setpaymentMethodText(
																`${
																	ekkLanguage.createOrder
																		.bankTransferText1
																} ${sellerName()} ${
																	ekkLanguage.createOrder
																		.bankTransferText2
																}:\n${
																	ekkLanguage.createOrder
																		.bankTransferText3
																}: Bank Asia\n${
																	ekkLanguage.createOrder
																		.bankTransferText4
																} EkkBaz Bangladesh Pvt. Ltd.\n${
																	ekkLanguage.createOrder
																		.bankTransferText5
																}: 04433001328\n${
																	ekkLanguage.createOrder
																		.bankTransferText6
																}: 070274187\n${
																	ekkLanguage.createOrder
																		.bankTransferText7
																}: Moghbazar Branch`
															);
														}
														setPayMethod(e.detail.value);

														let interVal = setTimeout(() => {
															dispatch(
																paymentMethod(e.detail.value)
															);
															dispatch(
																setPaymentMethodText(
																	e.detail.value
																)
															);
															clearTimeout(interVal);
														}, 10);
													}}>
													{/* <IonItem
														  lines='none'
														  className='bg-white border rounded-[10px] mb-5'>
														  <IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															  <div className='flex'>
																  <img
																	  src={delivery}
																	  alt='logo'
																  />{" "}
																  <span span className='ml-2'>
																	  {
																		  ekkLanguage.createOrder
																			  .cashOnDelivery
																	  }
																  </span>
															  </div>
														  </IonLabel>
														  <IonRadio
															  slot='start'
															  value='Cash on Delivery'
															  color='success'
														  />
													  </IonItem> */}
													{/* <IonItem
														  lines='none'
														  className='bg-white border rounded-[10px] mb-5 no-ripple'>
														  <IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
															  <div className='flex'>
																  <img
																	  src={cash}
																	  alt='logo'
																	  style={{
																		  height: 22,
																		  width: 35,
																	  }}
																  />{" "}
																  <span span className='ml-2'>
																	  {
																		  ekkLanguage.createOrder
																			  .cash
																	  }
																  </span>
															  </div>
														  </IonLabel>
														  <IonRadio
															  slot='start'
															  value='Cash'
															  color='success'
														  />
													  </IonItem> */}
													{!gateWayLoader ? (
														gateWayList.length ? (
															gateWayList?.map((el, i) => {
																return (
																	<IonItem
																		key={i}
																		lines='none'
																		className='relative bg-white border rounded-[10px] mb-5 no-ripple'
																		onClick={() =>
																			setSelectedMethod(el)
																		}>
																		<IonLabel className='absolute left-8 w-full border-0 font-sans text-14 text-black-500 font-medium'>
																			<div className='flex'>
																				<img
																					src={el?.image}
																					alt='logo'
																					style={{
																						height: 22,
																						width: 35,
																					}}
																				/>{" "}
																				<span
																					span
																					className='ml-2'>
																					{el?.title}
																				</span>
																			</div>
																		</IonLabel>
																		<IonRadio
																			// slot='start'
																			value={el?.title}
																			color='success'
																		/>
																	</IonItem>
																);
															})
														) : (
															""
														)
													) : (
														<div className='flex items-center justify-center h-[156px] m-auto'>
															{" "}
															<IonSpinner name='crescent'></IonSpinner>
														</div>
													)}
												</IonRadioGroup>
											</div>
										</div>
										<p className='font-normal text-12 text-black-500 px-2'>
											{/* {String(paymentMethodText)
												.split("\n")
												.map((el, i) => {
													return <p key={i}>{el}</p>;
												})} */}
											{selectedMethod?.description &&
												parse(selectedMethod?.description)}
										</p>
										<div className='text-center pt-4'>
											<IonButton
												color='primary'
												// onClick={paymentMethodModalClose}
												onClick={() =>
													setIsOpenPaymentMethod(false)
												}>
												{ekkLanguage.createOrder.btnLabel}
											</IonButton>
										</div>
									</Card>
								</div>
							</IonModal>
						</IonCol>
					</IonRow>
				</IonGrid>
			</Card>
		</div>
	);
}
