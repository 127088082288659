import {
	IonButton,
	IonDatetime,
	IonInput,
	IonItem,
	IonPopover,
} from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Calendar from "assets/images/icon/svg/Calendar";
import Check from "assets/images/icon/svg/Check";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function RentalDocument() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [date, setDate] = useState("");
	const [rentAmount, setRentAmount] = useState("");
	const navigate = useNavigate();

	const datePickerRef = useRef();
	const datePickerClose = () => {
		datePickerRef.current?.dismiss();
	};
	const [selectedDalieveryDate, setSelectedDalieveryDate] = useState(
		"Select rental start date"
	);
	// let daliveryDate = useSelector((state) => state.payment?.deliveryDate);

	// useEffect(() => {
	// 	dispatch(deliveryDate(moment(selectedDalieveryDate).format()));
	// }, [selectedDalieveryDate]);
	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='px-4 pt-6 pb-0'>
				<p className='text-16 font-semibold text-black-1000 pb-3'>
					Rental Start Date
				</p>
				<div className='pb-12'>
					<div
						className={`bg-F3F3F3 p-3 block rounded-[10px] mt-4 border ${
							selectedDalieveryDate
								? "border-transparent"
								: "border-primary"
						} `}
						style={{
							boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
						}}
						id='date-input'>
						<div className='flex justify-between items-center'>
							<div className='flex items-center'>
								<span>
									<Calendar
										color={
											selectedDalieveryDate ? "#222222" : "#FF564C"
										}
									/>
								</span>
								<p
									className={`text-12 ${
										selectedDalieveryDate
											? "border-transparent"
											: "text-primary"
									} ml-2`}>
									{selectedDalieveryDate}
								</p>
							</div>
							<div>
								<Add />
							</div>
						</div>
					</div>
					<IonPopover
						trigger='date-input'
						side='top'
						alignment='center'
						showBackdrop={true}
						ref={datePickerRef}>
						<ion-content>
							<IonDatetime
								// min={moment().format("YYYY-MM-DD")}
								weekValues='f'
								presentation='date'
								onIonChange={(ev) => {
									setSelectedDalieveryDate(ev.detail.value);
									// dispatch(
									// 	deliveryDate(
									// 		moment(selectedDalieveryDate).format()
									// 	)
									// );
								}}
							/>
							<div className='text-center py-2'>
								<IonButton color='primary' onClick={datePickerClose}>
									Confirm
								</IonButton>
							</div>
						</ion-content>
					</IonPopover>
				</div>
				<div>
					<p className='text-16 font-semibold text-black-1000 pb-3 '>
						Rent per Month
					</p>
					<IonItem className='mb-12'>
						<IonInput
							type='number'
							placeholder='amount'
							onIonChange={(e) =>
								setRentAmount(e.detail.value)
							}></IonInput>
					</IonItem>
				</div>
				{date.length && rentAmount.length ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}>
							<Check></Check> Select Complete.
						</p>
						<p
							className='font-normal text-14'
							style={{ color: "#808080" }}>
							Please click "
							<span style={{ color: "#00B224" }}>SUBMIT</span>" button
						</p>
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal p-[14px] text-14'
							style={{ color: "#808080" }}>
							Please, select your{" "}
							<b className='text-primary font-semibold'>
								“Rental Start Date”
							</b>{" "}
							& fill up your{" "}
							<b className='text-primary font-semibold'>
								“Rent per Month”
							</b>{" "}
							amount.
						</p>
					</div>
				)}
				<div className='pt-24 text-center pb-10'>
					<IonButton
						expand='block'
						color='primary'
						className='font-bold h-10'
						onClick={() => navigate("/vbproperty")}>
						{ekkLanguage.businessCreateName.submit}
						<span className='pl-2'>
							<ArrayRight />
						</span>
					</IonButton>
				</div>
				<div className='business-step text-center pb-10'>
					<ul className='flex justify-center items-center'>
						<li className='w-full relative active'>
							<div className='w-7 h-7 border-2 border-primary rounded-full text-white bg-primary z-10 relative'>
								{ekkLanguage.verifyBusiness.numberOne}
							</div>
						</li>
						<li className='w-full relative active'>
							<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-white z-10 relative bg-primary'>
								{ekkLanguage.verifyBusiness.numberTwo}
							</div>
						</li>
						<li className='w-full relative active'>
							<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-white z-10 relative bg-primary'>
								{ekkLanguage.verifyBusiness.numberThree}
							</div>
						</li>
						<li className='relative'>
							<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-white z-10 relative bg-primary'>
								{ekkLanguage.verifyBusiness.numberFour}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
