import { IonButton } from "@ionic/react";
import RightArrowThin from "assets/images/icon/svg/RightArrowThin";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function CardTitle({ title, link, icon }) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  return (
    <div className="flex justify-between items-center mb-[10px] pr-4">
      <div>
        <Link to={link} className="flex items-center">
          <img src={icon} className="pr-2 w-[30px] h-[30px]" alt="icon" />
          <h5 className={`font-bold text-20 ml-2 text-262626-1000`}>{title}</h5>
        </Link>
      </div>

      <Link
        to={link}
        className="text-0086B2 text-12 font-normal flex items-center"
      >
        {ekkLanguage.home.showMoreLabel}
        <span className="ml-2">
          <RightArrowThin />
        </span>
      </Link>
    </div>
  );
}
