import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonRow,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { Input } from "components/global-components/common/Input";
import {
  getCreateUserBusiness,
  getLocalUser,
  setCreateUserBusiness,
} from "config/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function BusinessName() {
  const [text, setText] = useState(getCreateUserBusiness().business_name);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const navigate = useNavigate();
  const [error, setError] = useState();
  const business = getCreateUserBusiness();
  useEffect(() => {
    if (text) {
      setError("");
    }
  }, [text]);
  const getName = (e) => {
    business.business_name = e;
    business.created_by = getLocalUser().user._id;
    setCreateUserBusiness(business);
  };

  return (
    <div className=" business-name px-2 pt-14">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="pt-5 text-center">
              <h5 className="text-black-1000 text-16 font-medium pb-6">
                {ekkLanguage.businessCreateName.paragraph}
              </h5>
              <img
                src="/assets/images/store.png"
                alt=""
                className="m-auto mb-6"
                style={{ width: 190 }}
              />
              <div className="text-left">
                {/* <h5 className="text-dark-1000 text-16 font-semibold">
                  {ekkLanguage.businessCreateName.businessName}
                </h5> */}
                <Input
                  onChange={(event) => {
                    setText(event.target.value);
                    getName(event.target.value);
                  }}
                  value={text}
                  type="text"
                  label={ekkLanguage.businessCreateName.businessName}
                  placeholder={
                    ekkLanguage.businessCreateName.businessNamePlaceholder
                  }
                  required
                />
                {/* <IonItem>
                  <IonInput
                    value={text}
                    placeholder={
                      ekkLanguage.businessCreateName.businessNamePlaceholder
                    }
                    onIonChange={(e) => {
                      setText(e.detail.value);
                      getName(e.detail.value);
                    }}
                    clearInput
                  ></IonInput>
                </IonItem> */}
                {error && (
                  <div className="text-primary mt-2 text-right">{error}</div>
                )}
              </div>

              <div className="pt-24">
                <IonButton
                  color="primary"
                  className="font-bold h-10 w-full"
                  onClick={() => {
                    if (text) {
                      navigate("/business-type");
                    } else {
                      setError(ekkLanguage.businessCreateName.errorLabel);
                    }
                  }}
                >
                  {ekkLanguage.businessCreateName.btnLabel}
                  <span className="pl-2">
                    <ArrayRight />
                  </span>
                </IonButton>
              </div>
              {/* <div className="business-step">
                <ul className="flex justify-center items-center">
                  <li className="w-full relative">
                    <div className="w-7 h-7 border-2 border-primary rounded-full text-white bg-primary z-10 relative">
                      {ekkLanguage.businessCreateName.numberOne}
                    </div>
                  </li>
                  <li className="w-full relative">
                    <div className="w-7 h-7 border-2 border-F94B41-200 rounded-full text-F94B41-200 z-10 relative bg-white">
                      {ekkLanguage.businessCreateName.numberTwo}
                    </div>
                  </li>
                  <li className="relative">
                    <div className="w-7 h-7 border-2 border-F94B41-200 rounded-full text-F94B41-200  z-10 relative bg-white">
                      {ekkLanguage.businessCreateName.numberThree}
                    </div>
                  </li>
                </ul>
              </div> */}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
