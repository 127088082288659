import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonRow,
  IonLoading,
  useIonAlert,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import LocationPin from "assets/images/icon/svg/LocationPin";
import {
  getCreateUserBusiness,
  getUser,
  setCreateUserBusiness,
  setLocalUser,
} from "config/user";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Auth from "services/Auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateToken } from "config/SuperFetch";
import { useSelector } from "react-redux";
import { postalCodeInfo } from "data/postalCode";
import Business from "services/Business";
import { Input } from "components/global-components/common/Input";

export default function BusinessLocation() {
  const location = useSelector((state) => state.user.mapLocation);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const ekkLanguage = useSelector((state) => state.lan.lan);
  let postalCode = postalCodeInfo.find((el) =>
    String(location.area).includes(el.thana)
  );

  const [postCode, setPostCode] = useState("");
  const [email, setEmail] = useState(getCreateUserBusiness().email);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [cityError, setCityError] = useState();
  const [postCodeError, setPostCodeError] = useState();

  const navigate = useNavigate();
  const data = getCreateUserBusiness();

  //   all business information list
  //   all business information
  // async function businessDataSave() {
  // 	if (address) {
  // 		if (city) {
  // 			if (postCode) {
  // 				try {
  // 					setLoading(true);
  // 					const response = await Auth.createUserBusiness(data);
  // 					if (response.success) {
  // 						let res = await Auth.refreshToken(response?.businesses);
  // 						if (res.status === 200) {
  // 							let userData = {
  // 								associated_businesses: [{ ...res.business_info }],
  // 								user: res?.user,
  // 							};
  // 							setLoading(false);
  // 							toast.success(
  // 								ekkLanguage.businessLocation.successLabel
  // 							);
  // 							localStorage.setItem(
  // 								"setUser",
  // 								JSON.stringify(userData)
  // 							);
  // 							updateToken(res);
  // 							navigate("/home");
  // 							localStorage.setItem("auth", true);
  // 						}
  // 					} else {
  // 						setLoading(false);
  // 					}
  // 				} catch (error) {
  // 					console.log(error);
  // 				}
  // 			} else {
  // 				setPostCodeError(
  // 					ekkLanguage.businessLocation.validationPostCodeLabel
  // 				);
  // 			}
  // 		} else {
  // 			setCityError(ekkLanguage.businessLocation.validationCityLabel);
  // 		}
  // 	} else {
  // 		setError(ekkLanguage.businessLocation.validationLabel);
  // 	}
  // }

  async function businessDataSave() {
    if (address) {
      if (city) {
        if (postCode) {
          try {
            setLoading(true);
            const response = await Business.createUserBusiness({
              ...data,
              business_group: "Buyer",
            });
            if (response.success) {
              let userData = {
                associated_businesses: [{ ...response.results }],
                user: response?.user,
              };

              setLoading(false);
              toast.success(ekkLanguage.businessLocation.successLabel);
              localStorage.setItem("setUser", JSON.stringify(userData));
              updateToken(response);
              navigate("/home");
              localStorage.setItem("auth", true);
            } else {
              setLoading(false);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          setPostCodeError(
            ekkLanguage.businessLocation.validationPostCodeLabel
          );
        }
      } else {
        setCityError(ekkLanguage.businessLocation.validationCityLabel);
      }
    } else {
      setError(ekkLanguage.businessLocation.validationLabel);
    }
  }

  useEffect(() => {
    if (address) {
      setError("");
    }
  }, [address]);

  return (
    <div className="business-location pt-14">
      {loading ? (
        <IonLoading
          isOpen={loading}
          // onDidDismiss={() => setShowLoading(false)}
          message={ekkLanguage.businessLocation.loader}
        />
      ) : (
        ""
      )}
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="pt-2 text-center">
              <h5 className="text-black-1000 text-16 font-medium pb-3">
                {ekkLanguage.businessLocation.paragraph}
              </h5>
              <div className="text-center">
                <span className="business-loation-pin">
                  <LocationPin />
                </span>
              </div>
              <div className="text-left">
                <IonRow className="pb-5">
                  <IonCol size="12">
                    <div>
                      {/* <h5 className='text-dark-1000 text-16 font-semibold'>
												{ekkLanguage.businessLocation.address}
											</h5>
											<IonItem>
												<IonInput
													value={address}
													placeholder={
														ekkLanguage.businessLocation
															.addressPlaceholder
													}
													onIonChange={(e) => {
														setAddress(e.detail.value);
														const business =
															getCreateUserBusiness();
														business.address_line =
															e.detail.value;
														setCreateUserBusiness(business);
													}}
													clearInput></IonInput>
											</IonItem> */}
                      <Input
                        onChange={(event) => {
                          setAddress(event.target.value);
                          const business = getCreateUserBusiness();
                          business.address_line = event.target.value;
                          setCreateUserBusiness(business);
                        }}
                        type="text"
                        label={ekkLanguage.businessLocation.address}
                        placeholder={
                          ekkLanguage.businessLocation.addressPlaceholder
                        }
                        required
                      />
                      {error && (
                        <div className="text-primary mt-1 text-right">
                          {error}
                        </div>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="pb-7">
                  <IonCol size="6">
                    <div>
                      {/* <h5 className="text-dark-1000 text-16 font-semibold">
                        {ekkLanguage.businessLocation.city}
                      </h5>
                      <IonItem>
                        <IonInput
                          value={city}
                          placeholder={
                            ekkLanguage.businessLocation.cityPlaceholder
                          }
                          onIonChange={(e) => {
                            setCity(e.detail.value);
                            const business = getCreateUserBusiness();
                            business.city = e.detail.value;
                            setCreateUserBusiness(business);
                          }}
                          clearInput
                        ></IonInput>
                      </IonItem> */}
                      <Input
                        onChange={(event) => {
                          setCity(event.target.value);
                          const business = getCreateUserBusiness();
                          business.city = event.target.value;
                          setCreateUserBusiness(business);
                        }}
                        type="text"
                        label={ekkLanguage.businessLocation.city}
                        placeholder={
                          ekkLanguage.businessLocation.cityPlaceholder
                        }
                        required
                      />
                      {cityError && (
                        <div className="text-primary mt-1 text-right">
                          {cityError}
                        </div>
                      )}
                    </div>
                  </IonCol>
                  <IonCol size="6">
                    <div>
                      {/* <h5 className="text-dark-1000 text-16 font-semibold">
                        {ekkLanguage.businessLocation.postalCode}
                      </h5>
                      <IonItem>
                        <IonInput
                          value={postCode}
                          placeholder={
                            ekkLanguage.businessLocation.postalCodePlaceholder
                          }
                          onIonChange={(e) => {
                            setPostCode(e.detail.value);

                            const business = getCreateUserBusiness();
                            business.postal_code = e.detail.value;
                            setCreateUserBusiness(business);
                          }}
                          clearInput
                        ></IonInput>
                      </IonItem> */}
                      <Input
                        onChange={(event) => {
                          setPostCode(event.target.value);
                          const business = getCreateUserBusiness();
                          business.postal_code = event.target.value;
                          setCreateUserBusiness(business);
                        }}
                        type="number"
                        label={ekkLanguage.businessLocation.postalCode}
                        placeholder={
                          ekkLanguage.businessLocation.postalCodePlaceholder
                        }
                        required
                      />
                      {postCodeError && (
                        <div className="text-primary mt-1 text-right">
                          {postCodeError}
                        </div>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                {/* <IonRow>
                  <IonCol size="12">
                    <div>
                      <h5 className="text-dark-1000 text-16 font-semibold">
                        {ekkLanguage.businessLocation.email}
                      </h5>
                      <IonItem>
                        <IonInput
                          type="email"
                          value={email}
                          placeholder={
                            ekkLanguage.businessLocation.emailPlaceholder
                          }
                          onIonChange={(e) => {
                            setEmail(e.detail.value);
                            const business = getCreateUserBusiness();
                            business.email = e.detail.value;
                            setCreateUserBusiness(business);
                          }}
                          clearInput
                        ></IonInput>
                      </IonItem>
					  <Input
                        onChange={(event) => {
                          setEmail(event.target.value);
                          const business = getCreateUserBusiness();
                          business.email = event.target.value;
                          setCreateUserBusiness(business);
                        }}
                        type="text"
                        label={ekkLanguage.businessLocation.email}
                        placeholder={
                          ekkLanguage.businessLocation.emailPlaceholder
                        }
                      />
                    </div>
                  </IonCol>
                </IonRow> */}
              </div>
              <div className="pt-24">
                <IonButton
                  color="primary"
                  className="font-bold h-10 w-full"
                  onClick={() => {
                    businessDataSave();
                  }}
                >
                  {ekkLanguage.businessLocation.btnLabel}
                </IonButton>
              </div>
              {/* <div className="business-step">
                <ul className="flex justify-center items-center">
                  <li className="w-full relative active">
                    <div className="w-7 h-7 border-2 border-primary rounded-full text-white bg-primary z-10 relative">
                      {ekkLanguage.businessLocation.numberOne}
                    </div>
                  </li>
                  <li className="w-full relative active">
                    <div className="w-7 h-7 border-2 border-primary rounded-full text-white bg-primary z-10 relative">
                      {ekkLanguage.businessLocation.numberTwo}
                    </div>
                  </li>
                  <li className="relative active">
                    <div className="w-7 h-7 border-2 border-F94B41-200 border-primary rounded-full text-white bg-primary z-10 relative">
                      {ekkLanguage.businessLocation.numberThree}
                    </div>
                  </li>
                </ul>
              </div> */}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
