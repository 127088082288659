import { createSlice, createSelector } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		loading: false,
		warning: true,
		data: {},
		location: false,
		locationData: null,
		bottomSheet: false,
		businessBottomSheet: false,
		bankBottomSheet: false,
		pwaDownloadData: {},
		mapLocation: {},
		createUserData: {},
	},
	reducers: {
		setUser: (state, action) => {
			state.data = action.payload;
		},
		setLocation: (state, action) => {
			state.location = true;
			state.locationData = action.payload;
		},
		setBottomSheet: (state, action) => {
			state.bottomSheet = action.payload;
		},
		setBusinessBottomSheet: (state, action) => {
			state.businessBottomSheet = action.payload;
		},
		setAddBankBottomSheet: (state, action) => {
			state.bankBottomSheet = action.payload;
		},

		setPwaDownloadData: (state, action) => {
			state.pwaDownloadData = action.payload;
		},
		setMapLocation: (state, action) => {
			state.mapLocation = action.payload;
		},
		setCreateUserData: (state, action) => {
			state.createUserData = action.payload;
		},
		setWarning: (state, action) => {
			state.warning = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = userSlice;

export const selectors = {
	getLocation: createSelector(
		(state) => state.user,
		(location) => location
	),
};

export const setLocation = (values) => async (dispatch, getState) => {
	dispatch(actions.setLocation(values));
};
export const setUser = (data) => async (dispatch, getState) => {
	dispatch(actions.setUser(data));
};
export const setBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBottomSheet(data));
};
export const setBusinessBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBusinessBottomSheet(data));
};
export const setAddBankBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setAddBankBottomSheet(data));
};
export const setPwaDownloadData = (data) => async (dispatch) => {
	dispatch(actions.setPwaDownloadData(data));
};
export const setMapLocation = (data) => async (dispatch) => {
	dispatch(actions.setMapLocation(data));
};
export const setCreateUser = (data) => async (dispatch) => {
	dispatch(actions.setCreateUserData(data));
};
export const setWarning = (data) => async (dispatch) => {
	dispatch(actions.setWarning(data));
};
// Export the reducer, either as a default or named export
export default reducer;
