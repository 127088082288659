import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MenuLeftArrow from "assets/images/icon/svg/MenuLeftArrow";
import Search from "assets/images/icon/svg/Search";
import Cross from "assets/images/icon/svg/Cross";
import CrossBig from "assets/images/icon/svg/CrossBig";
export default function BackButtonTitle({
	title,
	getData,
	searchQ,
	setSearchQ,
	itemsSearch,
	placeholder,
	fetchSearchData,
	initData,
	deleteQuery,
	inputType,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const [searchOpen, setSearchOpen] = useState(false);
	const [tempSearchParams, setTempSearchParams] = useState("");
	const numberInputRef = useRef(null);
	useEffect(() => {
		const handleWheel = (event) => {
			if (
				numberInputRef.current &&
				numberInputRef.current === document.activeElement
			) {
				event.preventDefault();
			}
		};
		window.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			window.removeEventListener("wheel", handleWheel);
		};
	}, []);
	return (
		<>
			<div
				className={`bg-primary z-40 flex pl-4 items-center justify-between py-2 pt-2 px-2 fixed left-0 top-0 w-full pr-4 ${
					tempSearchParams ? "pb-1" : "pb-2"
				}`}>
				<div className='flex items-center justify-between w-full'>
					<div className='flex items-center w-full justify-start'>
						<div
							className='flex items-center justify-start h-10 rounded-[10px] cursor-pointer'
							onClick={() => {
								if (location.pathname === "/create-order") {
									let localCart = localStorage.getItem("cart");
									let cartParse = JSON.parse(localCart);
									if (cartParse?.length > 0) {
										setIsOpen(true);
									} else {
										navigate(-1);
									}
								} else {
									navigate(-1);
								}
							}}>
							<MenuLeftArrow />
						</div>
						<div className={`mx-5 w-full`}>
							{searchOpen ? (
								<div>
									<div className={`relative`}>
										<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
											<Search color='rgba(0,0,0,.6)' />
										</span>
										<input
											ref={numberInputRef}
											type={inputType}
											className='bg-white w-full h-[38px] rounded-[7px] pl-12 search-input border'
											placeholder={placeholder}
											style={{
												boxShadow:
													"1px 2px 8px rgba(0, 0, 0, 0.2) ",
											}}
											value={searchQ}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													fetchSearchData(e, searchQ);
													setTempSearchParams(searchQ);
												}
											}}
											onChange={(e) =>
												setSearchQ(e.target.value)
											}></input>
										{searchQ && (
											<span
												className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
												onClick={deleteQuery}>
												<Cross />
											</span>
										)}
									</div>

									{tempSearchParams && (
										<p className='px-4 pt-1 text-10 text-white'>
											<span>
												Search:{" "}
												<span className='font-bold'>
													{" "}
													{tempSearchParams}
												</span>
											</span>
										</p>
									)}
								</div>
							) : (
								<h5 className='text-white text-18 font-bold'>
									{title}
								</h5>
							)}
						</div>
					</div>

					<div
						className='h-10 flex items-center cursor-pointer'
						onClick={() => {
							setSearchOpen(!searchOpen);
							if (searchOpen) {
								setTempSearchParams("");
								setSearchQ("");
								initData();
							}
						}}>
						{searchOpen ? <CrossBig /> : <Search />}
					</div>
				</div>
			</div>
		</>
	);
}
