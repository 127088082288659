import { getLanguage } from "config/language";
import React from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Departments({ departmentList }) {
  let navigate = useNavigate();
  const appLanguage = getLanguage();
  return (
    <div className="product-slider">
      <Swiper
        spaceBetween={0}
        slidesPerView={2.5}
        grabCursor={true}
        breakpoints={{
          260: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 3,
          },
          360: {
            slidesPerView: 4.5,
          },
          575: {
            slidesPerView: 4.5,
          },
          768: {
            slidesPerView: 5.5,
          },
        }}
      >
        {departmentList?.map((el, i) => {
          return (
            <SwiperSlide
              key={i}
              onClick={() => navigate(`/department/${el.id}/${appLanguage == "bn" ? el?.title_in_native : el?.title}`)}
            >
              <div className="text-center">
                <div
                  className="rounded-full h-[50px] w-[50px] flex m-auto"
                  style={{
                    background: "rgba(0, 178, 36, 0.15)",
                    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <img src={el?.image} className="m-auto" alt="icon" />
                </div>
                <p className="text-12 font-semibold mt-[7px] text-262626-1000">
                  {appLanguage == "bn" ? el?.title_in_native : el?.title}
                </p>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
