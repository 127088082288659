import React from "react";

export default function CrossBig() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<g id='fi-rr-cross-small'>
				<path
					id='Vector'
					d='M17.9998 5.99997C17.8123 5.8125 17.558 5.70718 17.2928 5.70718C17.0277 5.70718 16.7733 5.8125 16.5858 5.99997L11.9998 10.586L7.41382 5.99997C7.22629 5.8125 6.97198 5.70718 6.70682 5.70718C6.44165 5.70718 6.18735 5.8125 5.99982 5.99997C5.81235 6.1875 5.70703 6.44181 5.70703 6.70697C5.70703 6.97213 5.81235 7.22644 5.99982 7.41397L10.5858 12L5.99982 16.586C5.81235 16.7735 5.70703 17.0278 5.70703 17.293C5.70703 17.5581 5.81235 17.8124 5.99982 18C6.18735 18.1874 6.44165 18.2928 6.70682 18.2928C6.97198 18.2928 7.22629 18.1874 7.41382 18L11.9998 13.414L16.5858 18C16.7733 18.1874 17.0277 18.2928 17.2928 18.2928C17.558 18.2928 17.8123 18.1874 17.9998 18C18.1873 17.8124 18.2926 17.5581 18.2926 17.293C18.2926 17.0278 18.1873 16.7735 17.9998 16.586L13.4138 12L17.9998 7.41397C18.1873 7.22644 18.2926 6.97213 18.2926 6.70697C18.2926 6.44181 18.1873 6.1875 17.9998 5.99997Z'
					fill='white'
				/>
			</g>
		</svg>
	);
}
