import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import LeftArrow from "assets/images/icon/svg/LeftArrow";
import { digitChanger, numberWithCommas, translator } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrderSingle } from "redux/container/orderSlice";

export default function ReceiptPage() {
	let { id } = useParams();
	const dispatch = useDispatch();
	const printAriaRef = useRef();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const response = useSelector((state) => state.order?.singleOrder);
	useEffect(() => {
		dispatch(fetchOrderSingle(id));
	}, []);
	const navigate = useNavigate();
	return (
		<IonApp>
			<IonPage
				className='business-name mx-auto'
				id='ok'
				style={{ maxWidth: 220, width: "100%" }}>
				<IonContent>
					<div className='bg-white'>
						<div className='intro-y flex items-center mt-8'>
							<div className='w-full flex mt-4 sm:mt-0 pr-4 justify-between items-center pl-4'>
								<div
									className='short-nav left-4 top-7 bg-E6E6E6 rounded-xl z-10 '
									style={{
										height: 40,
										width: 40,
										boxShadow: "0 5px 10px rgba(0,0,0,.1)",
									}}
									onClick={() => navigate(-1)}>
									<div className=' h-full flex items-center justify-center'>
										<LeftArrow />
									</div>
								</div>
								<IonButton
									className='bg-primary rounded-[7px] font-extrabold text-12 h-10'
									onClick={() => window.print()}>
									Print
								</IonButton>
							</div>
						</div>
						{/* BEGIN: Invoice */}
						<div
							className='intro-y box overflow-hidden text-center m-auto px-4 pt-10'
							ref={printAriaRef}
							style={{ width: 219 }}>
							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='text-center pb-[2px]'>
									<h6 className='text-black-1000 text-12 font-bold'>
										{response?.buyer?.business_name}
									</h6>
									<h6 className='text-black-1000 text-12 font-bold pb-[2px]'>
										{digitChanger(response?.buyer?.phone_number)}
									</h6>
									<p className='text-black-1000 text-8 font-normal'>
										{response?.buyer?.address_line}
									</p>
								</div>
							</div>
							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between pb-[2px]'>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(response?.order_id)}
									</p>
									<p className='text-black-1000 text-8 font-bold'>
										{ekkLanguage.receipt.placed}
									</p>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(
											moment(response?.createdAt).format(
												"DD-MM-YYYY"
											)
										)}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{response?.created_by_user?.DisplayName}
									</p>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(
											moment(response?.createdAt).format("HH:MM:SS")
										)}
									</p>
								</div>
							</div>

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='text-center pb-[2px]'>
									<h6 className='text-black-1000 text-12 font-bold'>
										{response?.seller?.business_name}
									</h6>
								</div>
							</div>

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='pb-[2px]'>
									<div className='flex justify-between pb-[2px]'>
										<p
											className='text-black-1000 text-10 font-bold text-left'
											style={{ width: 108 }}>
											{ekkLanguage.receipt.name}
										</p>
										<p
											className='text-black-1000 text-10 font-bold'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.qty}
										</p>
										<p
											className='text-black-1000 text-10 font-bold text-right'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.price}
										</p>
									</div>
									{response?.order_items?.map((el, i) => {
										return (
											<div
												className='flex justify-between pb-[2px]'
												key={i}>
												<p
													className='text-black-1000 text-8 font-normal text-left'
													style={{ width: 108 }}>
													{el?.catalog?.product?.title}
												</p>
												<p
													className='text-black-1000 text-8 font-normal'
													style={{ width: 20 }}>
													{digitChanger(el?.quantity)}
												</p>
												<p
													className='text-black-1000 text-8 font-normal text-right'
													style={{ width: 50 }}>
													{digitChanger(
														numberWithCommas(
															el?.quantity * el?.unit_price
														)
													)}
												</p>
											</div>
										);
									})}
								</div>
							</div>
							<div className='bottom-1 border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='text-center pb-[2px]'>
									<div className='flex justify-between pb-[2px]'>
										<p className='text-black-1000 text-10 font-bold text-left'>
											{ekkLanguage.receipt.subtotal}
										</p>
										<p className='text-black-1000 text-10 font-bold text-right'>
											{digitChanger(
												numberWithCommas(response?.sub_total)
											)}
										</p>
									</div>
									<div className='flex justify-between'>
										<p
											className='text-black-1000 text-8 font-normal text-left'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.tax}
										</p>
										<p className='text-black-1000 text-8 font-normal text-left'>
											{digitChanger(
												numberWithCommas(response?.tax_total)
											)}
										</p>
										<p className='text-black-1000 text-8 font-normal text-right'>
											{digitChanger(
												numberWithCommas(response?.tax_total)
											)}
										</p>
									</div>
									<div className='flex justify-between'>
										<p
											className='text-black-1000 text-8 font-normal text-left'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.discount}
										</p>
										{/* <p className='text-black-1000 text-8 font-normal text-left'>
											05%
										</p> */}
										<p className='text-black-1000 text-8 font-normal text-right'>
											{digitChanger(numberWithCommas(0.0))}
										</p>
									</div>
									<div className='flex justify-between'>
										<p
											className='text-black-1000 text-8 font-normal text-left'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.creditFee}
										</p>
										<p className='text-black-1000 text-8 font-normal text-left'>
											{digitChanger(
												numberWithCommas(
													response?.payment_term?.creditFee
												)
											)}
										</p>
										<p className='text-black-1000 text-8 font-normal text-right'>
											{digitChanger(
												numberWithCommas(
													response?.payment_term?.creditFee
												)
											)}
										</p>
									</div>
									<div className='flex justify-between'>
										<p
											className='text-black-1000 text-8 font-normal text-left'
											style={{ width: 50 }}>
											{ekkLanguage.receipt.deliveryFee}
										</p>
										{/* <p className='text-black-1000 text-8 font-normal text-left'>
											05%
										</p> */}
										<p className='text-black-1000 text-8 font-normal text-right'>
											{digitChanger(
												numberWithCommas(response?.sub_total)
											)}
										</p>
									</div>
								</div>
							</div>
							<hr className='border-b border-dashed  pt-[2px] border-black-500' />

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-bold'>
										{ekkLanguage.receipt.total}
									</p>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(numberWithCommas(response?.total))}
									</p>
								</div>
							</div>

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.paid}
									</p>
									<p className='text-black-1000 text-8 font-normal'>
										{digitChanger(
											numberWithCommas(response?.total_paid)
										)}
									</p>
								</div>
							</div>

							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-bold'>
										{ekkLanguage.receipt.totalDue}
									</p>
									<p className='text-black-1000 text-10 font-bold'>
										{digitChanger(
											numberWithCommas(
												response?.total - response?.total_paid
											)
										)}
									</p>
								</div>
							</div>
							<div className='bottom-1 border-b border-dashed  pt-[5px] pb-[5px] border-black-500'>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.paymentTerms}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										{digitChanger(response?.payment_term?.duration)}
										{ekkLanguage.receipt.days}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-bold'>
										{ekkLanguage.receipt.paymentMethod}
									</p>
									<p className='text-black-1000 text-10 font-bold'>
										{response?.payment_method}
									</p>
								</div>
								{response?.payment_method === "Bank Transfer" && (
									<div className='flex justify-between'>
										<p className='text-black-1000 text-8 font-bold'>
											{ekkLanguage.receipt.referenceNumber}
										</p>
										<p className='text-black-1000 text-10 font-bold'>
											{digitChanger(response?.order_id)}
										</p>
									</div>
								)}{" "}
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.deliveryDate}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										{digitChanger(
											moment(
												response?.payment_term?.createdAt
											).format("DD-MM-YYYY")
										)}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.dueDate}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										{digitChanger(
											moment(
												response?.payment_term?.paymentDueDate
											).format("DD-MM-YYYY")
										)}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-8 font-normal'>
										{ekkLanguage.receipt.printTime}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										{digitChanger(moment(Date.now()).format("HH:mm"))}
									</p>
								</div>
							</div>
							<div className='pt-[5px] border-black-500'>
								<div className='flex justify-center'>
									<p className='text-black-1000 text-8 font-bold'>
										{ekkLanguage.receipt.conditionsCreditFee}
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.immediatePayment}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										No
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.cashOnDelivery}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										No
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.atNight}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										0.001%
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.payInthreeDay}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										0.005%
									</p>
								</div>
								<div className='flex justify-between'>
									<p className='text-black-1000 text-10 font-normal'>
										{ekkLanguage.receipt.payInSevenDay}
									</p>
									<p className='text-black-1000 text-10 font-normal'>
										0.01%
									</p>
								</div>
								<div className='flex justify-between'>
									<p
										className='text-black-1000 text-10 font-normal text-left'
										style={{ width: "58%" }}>
										{ekkLanguage.receipt.afterSeven}
									</p>
									<p className='text-black-1000 text-10 font-normal text-right'>
										0.01% + 0.002%
									</p>
								</div>
							</div>
						</div>
						{/* END: Invoice */}
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
